import React from "react";
import '../App.css';
// import gzLogo from "../assets/images/aug21icons.gif";

function Footer() {
  return (
    <div>
        <footer>
            <h5>&copy;2021 GREG ZARAGOZA</h5><br />
            <h5>HECHO CON AMOR EN SAN ANTONIO, TX</h5>
        </footer>
    </div>
  );
}

export default Footer;